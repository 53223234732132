<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('settings.active_providers')"
    />
    <section class="wameed-dashboard-page-content_body">
      <b-row class="px-2">
        <b-col
            lg="12"
            md="12"
            class="px-0"
        >
          <b-card
              no-body
              class="cursor-pointer wameed-card"
          >
            <b-card-header class="align-items-start tab-title">
              <h5 class="text-reg-18 text-font-main">
                {{ $t('settings.active_providers') }}
              </h5>
            </b-card-header>
            <wameed-form
                refs=""
                @submitAction="submit"
            >
              <template
                  slot="form-content"
                  slot-scope="{ invalid }"
              >
                <b-card-body
                    class="position-relative py-5 justify-content-between px-5"
                >
                  <!-- form -->
                  <div class="d-flex flex-wrap py-4">
                    <b-col
                        cols="12"
                        md="12"
                    >
                      <text-input
                          id="login-email"
                          v-model="form.email"
                          :label="$t('common.email')"
                          input-classes="text-reg-14 "
                          prepen-icon="email-icon"
                          field-classes="pb-1 login-inputs"
                          name="email"
                          rules="required|email"
                          placeholder="john@example.com"
                      />
                    </b-col>

                  </div>
                </b-card-body>
                <b-card-footer class="px-4 py-3">
                  <div class="d-flex flex-wrap justify-content-between">
                    <div class="px-4 py-3">
                      <wameed-btn
                          classes="  text-med-14 text-white rounded-10"
                          :title="$t('common.activate')"
                          :disabled="invalid"
                          type="submit"
                          variant="main"
                      />
                    </div>
                    <div class="px-4 py-3">
                      <wameed-btn
                          classes="  text-med-14 text-font-secondary rounded-10 "
                          :title="$t('common.cancel')"
                          type="button"
                          variant="gray"
                          @submitAction="goBack"
                      />
                    </div>
                  </div>
                </b-card-footer>
              </template>
            </wameed-form>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import WameedForm from '@/components/WameedForm.vue';

import WameedBtn from '@/components/WameedBtn.vue';
import {mapActions} from 'vuex';

import TextInput from '@/components/TextInput.vue';
import PageHeader from '@/components/PageHeader.vue';

export default {
  components: {
    TextInput,
    WameedBtn,
    WameedForm,
    PageHeader,
  },
  data() {
    return {
      form: {
        email: '',
      },
    };
  },

  methods: {
    ...mapActions({
      adminActiveProvider: 'adminActiveProvider',
    }),

    goBack() {
      this.$router.push({name: 'settings'});
    },
    submit() {
      this.adminActiveProvider(this.form)
    },
  },
};
</script>
